import { useEffect, useState } from "react";
import { apiBlue, apiVtex } from "./services/api";

import logoNegative from './Assets/img/logo-branco.png'
import imgLoading from './Assets/img/gif-loader.gif'

import './Assets/css/global.css';

import axios from "axios";
import fs_react from 'fs-react'

function App() {

  const [gtin, setGtin] = useState('')
  const [loading, setLoading] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [time, setTime] = useState()
  const [product, setProduct] = useState({
    name: '',
    price: {
      offer: '',
      normal: ''
    },
    image: '',
    active: 0
  })

  async function getGtin(e) {
    setLoading(true)
    setProduct({
      name: '',
      price: {
        offer: '',
        normal: ''
      },
      image: '',
      active: 0
    })
    setNotFound(false)
    let image, productId, name, notFound;
    clearTimeout(time)
    try {
      const sku = await apiVtex.post(`/leitor`, {ean: gtin})
      name = sku.data.name
      productId = sku.data.refId
      image = sku.data.urlImage[0].ImageUrl
    } catch (error) {
      try {
        const productInfo = await apiBlue.get(`produtos/gtin/${gtin}`)
        name = productInfo.data.descricao
        productId = productInfo.data.produtoKey
      } catch (error) {
        notFound = true
      }
    }

    if (notFound) {
      setNotFound(notFound)
    } else {
      const prices = await apiBlue.get(`produtos/${productId}/precos`)
      const preco = prices.data.precos.find(price => price.lojaKey === 1)
      setProduct({
        name: name,
        price: {
          offer: preco.precoPromocao ? preco.precoPromocao.toString().replace('.', ',') : '',
          normal: preco.precoNormal.toString().replace('.', ','),
        },
        image: image,
        active: 1
      })
    }

    setLoading(false)
    setGtin('')
    resetTime()
  }

  function resetTime() {
    setTime(setTimeout(() => {
      setProduct({
        name: '',
        price: {
          offer: '',
          normal: ''
        },
        image: '',
        active: 0
      })

      setNotFound(false)
    }, 5000)
    )
  }

  // async function read() {
  //   const res = await axios.get('carga.json')
  //   console.log(res)
  // }

  // const handleSaveToPC = (jsonData) => {
  //   const fileData = JSON.stringify(jsonData);
  //   const blob = new Blob([fileData], { type: 'text/plain' });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.download = 'carga.json';
  //   link.href = url;
  //   link.click();
  // };

  useEffect(() => {
    // read()
    document.getElementById('inputEan').focus()
    document.getElementById('inputEan').addEventListener('blur', function () {
      document.getElementById('inputEan').focus()
    })
  }, [])

  return (
    <>
      <div className='container_app'>
        <input autoComplete="off" id='inputEan' type='text' value={gtin} name='gtin' onChange={e => setGtin(e.target.value)} onKeyDown={e => e.keyCode === 13 && getGtin()} />

        <div className='logo'>
          <img src={logoNegative} alt="" />
        </div>

        {loading &&
          <div className='loading fullwidth'>
            <img src={imgLoading} alt="" />
            <p>Carregando...</p>
          </div>
        }

        {notFound &&
          <div className='not_found fullwidth'>
            <p><b>Ops!</b></p>
            <p>Não localizamos esse produto.</p>
          </div>
        }

        {product.active === 1 &&
          <div className={`product fullwidth ${!product.image ? 'not_image' : ''}`}>
            {product.image &&
              <div className='product_image'>
                <img src={product.image} alt={product.name} />
              </div>
            }

            <div className='product_infos'>
              <h1 className='product_name'>{product.name}</h1>
              <div className='product_prices'>
                {product.price.offer ?
                  <>
                    <span className='price_normal'>De: <b>R$ {product.price.normal}</b> por:</span>
                    <span className='price_offer'>R$ {product.price.offer}</span>
                  </>
                  : <span className='price_offer'>R$ {product.price.normal}</span>
                }

              </div>
            </div>
          </div>
        }
      </div>


    </>
  );
}

export default App;

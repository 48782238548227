import axios from "axios";

const apiBlue = axios.create({
	baseURL: 'https://erp.bluesoft.com.br/lojasantoantonio/api/comercial/',
	headers: {
		'X-Customtoken': 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJ1c2VyTmFtZVwiOlwicm9tdWxvXCIsXCJzZWNyZXRcIjpcInVNQU9Md1RMVUV5bXl2Z05hZVpaVkx6R2d1THFvcWd4ZFp4Q1dqSnhrV1wifSJ9.eWxr3WidgvDtCK0Ep5PWq9WKP3HbODlW6boTR9HNt5II8YnpumcQ60COvdPb7vt9ZWnMfsvpLBEISQU-9Xbu9w'
	}
})

const apiVtex = axios.create({
	baseURL: 'https://santolabs.com.br/api/dasboard',
	// mode: 'cors',
	// cache: 'default',
	// headers: {
	// 	'content-type': 'application/json',
	// 	'Accept': 'application/json',
	// 	'X-VTEX-API-AppKey': 'vtexappkey-lojasantoantonio-XLPUCW',
	// 	'X-VTEX-API-AppToken': 'GJSGFJQEZOGMGGBCSWTNVTUGVQXLIYHGQBGTGQDKTASDVSLZDBWNVLAMOLLPMEVAHZVEZIVBTITUBQKCFYIGBULEBUFVFOYOFRWWWHZUGVUMRFLYBIJNWOZNOQCEDAVE'
	// }
})

export { apiBlue, apiVtex }

// https://thingproxy.freeboard.io/fetch/
// 'https://cors-anywhere.herokuapp.com/https://lojasantoantonio.vtexcommercestable.com.br/'